import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import bepassCopyright from './images/bepass_logo_default.png'
import bepassCopyrightPb from './images/bepass_logo_pb.png'
import bepassLogo from './images/bepass_logo.png'
import batfair from './images/betfair_logo.png'
import './App.sass'

import { BASE } from './basedata/constants'

import FaceOnboard from './Biometric/FaceOnboard'
import FaceInstructions from './Biometric/FaceInstructions'
import UserSignup from './User/UserSignup'
import Welcome from './Welcome'
import SuccessHandler from './Biometric/StatesComponents/SuccessHandler'
import { Card } from 'react-bootstrap'

import { createGlobalStyle } from 'styled-components';

function App() {
  const [userState, setuserState] = useState<string>('')
  const [userToken, setuserToken] = useState<any>('')

  const [loading, setloading] = useState(false)

  const [primaryColor, setPrimaryColor] = useState('#8ac91a');
  const [secondaryColor, setSecondaryColor] = useState('#476c07');
  const [tertiaryColor, setTertiaryColor] = useState('#476c07');
  const [demoLogo, setDemoLogo] = useState('');
  const [copyrightLogo, setCopyrightLogo] = useState('');
  const [backgroundColor, setBackgroundColor] = useState(['#8ac91a', '#476c07']);

  const handleResponse = useCallback((message: any) => {
    setuserState(message.step)
    if (message.token) {
      setuserToken(message.token)
    }
  }, [])

  const verifyBiometry = (token: any) => {


    fetch(`${BASE.api.base_url}${BASE.api.validate_face}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        "token": token
      })
    })
      .then((resg) => resg.json())
      .then((response) => {



        if (response.biometry) {

          if (response.callbackUrl) {

            const redirect = window.sessionStorage.setItem('callbackurl', `${response.callbackUrl}`)

          }
          setuserState('facesuccess')


        } else {
          setuserState('faceinformation')
        }


      })
      .catch((error) => {
        return false
      })
  }

  const getOnboardingData = () => {

    setloading(true)

    const slug = window.location.pathname.split('/')[1]

    if (!slug) {
      setloading(false)
      return false
    }

    fetch(`${BASE.dashboard_api.base_url}${BASE.dashboard_api.get_company_onboarding}/${slug}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      }
    })
      .then((resg) => resg.json())
      .then((response) => {

        setPrimaryColor(response.main_color)
        setSecondaryColor(response.secondary_color)
        setTertiaryColor(response.tertiary_color)
        setDemoLogo(response.logo)
        setCopyrightLogo(response.bepass_logo)
        setBackgroundColor(JSON.parse(response.background_color))

        if (response.default_step === 'face') {
          setuserToken('xxxxxxx')
          window.sessionStorage.setItem('token', 'xxxxxxx')
          setloading(false)
          return setuserState('faceinformation')
        } else {
          window.sessionStorage.removeItem('token')
        }

        setloading(false)

      })
      .catch((error) => {
        setloading(false)
        return false
      })
  }

  useEffect(() => {

    getOnboardingData()
    const params = new URLSearchParams(window.location.search)
    const generatedToken = window.sessionStorage.getItem('token')
    if (params.get('token')) {
      const currentToken = params.get('token'),
        currentCompany = params.get('orc')
      setuserToken(params.get('token'))
      window.sessionStorage.setItem('token', currentToken + '')
      window.sessionStorage.setItem('originCompany', currentCompany + '')

      return verifyBiometry(currentToken)
      // setuserState('faceinformation')
    }

    else if (generatedToken) {
      const currentToken = generatedToken
      setuserToken(generatedToken)
      return verifyBiometry(generatedToken)
      // setuserState('faceinformation')
    } else {
      setuserState('signup')
    }

  }, [])


  const GlobalStyle = createGlobalStyle`
  body {
    background: linear-gradient(0deg, ${backgroundColor[0]} 0%, ${backgroundColor[1]} 100%) no-repeat
  }
`;

  if (loading) {
    return (<></>)
  }
  return (
    <>
      <GlobalStyle />
      <div style={{ '--pm-dark': secondaryColor, '--pm-primary': primaryColor } as CSSProperties} className="Container">
        <div className="logos">
          <img src={demoLogo ? demoLogo : bepassLogo} className="logo" alt="BePass" />
        </div>

        {userState === 'faceinformation' ? (
          <FaceInstructions onResponse={handleResponse} />
        ) : userState === 'facelogin' ? (
          <FaceOnboard token={userToken} />
        ) : userState === 'faceproceed' ? (
          <FaceOnboard token={userToken} />
        ) : userState === 'signup' ? (
          <UserSignup onResponse={handleResponse} />
        ) : userState === 'facesuccess' ? (
          <Card>
            <SuccessHandler />
          </Card>
        ) : userState === 'welcome' ? (
          <Welcome onResponse={handleResponse} />
        ) : <></>}
      </div>
      <p className="copyright">
        powered by{' '}
        <a href="http://bepass.com.br" rel="noreferrer" target="_blank">
          <img src={copyrightLogo === 'white' ? bepassCopyrightPb : bepassCopyright} className="logo-bepass" alt="bepass" />
        </a>
      </p>
    </>
  )
}

export default App
